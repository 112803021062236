export const navItems = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer'
  },
  {
    name: 'Dashboard',
    url: '/supplier/dashboard',
    icon: 'icon-speedometer',
    data: {
      permissions: 'only_supplier',
      onlySupplier: true
    }
  },
  {
    name: 'Projects', // supplier projects
    url: '/supplier',
    icon: 'fa fa-cube',
    data: {
      permissions: 'view_supplier_projects,create_supplier_bids,view_supplier_bids',
      onlySupplier: true
    },
    children: [
      {
        name: 'Bid On Projects',
        url: '/supplier/supplier-project',
        data: {
          permissions: 'view_supplier_projects',
          onlySupplier: true
        },
      }
    ]
  },
  {
    name: 'Users',
    url: '/user',
    // icon: 'fa fa-users',
    data: {
      onlyAdmin: true
    }
  },
  {
    name: 'User Hierarchy',
    url: '/user-relation',
    // icon: 'fa fa-users',
    data: {
      onlyAdmin: true
    }
  },
  {
    name: 'Projects', // project tab for salesRep, Estimator, Estimator HOD
    url: '/project',
    icon: 'fa fa-cube',
    data: {
      permissions: 'view_projects,create_projects,update_projects,create_project_requirements,' +
        'update_project_requirements,view_project_bids,update_estimation,create_estimation', // view_estimation
    }
  },
  {
    name: 'Project History', // project tab for salesRep, Estimator, Estimator HOD
    url: '/project-history',
    icon: 'fa fa-history',
    data: {
      permissions: 'view_projects,create_projects,update_projects,create_project_requirements,' +
        'update_project_requirements,view_project_bids,update_estimation,create_estimation,view_estimation,view_sales_order,view_orders_accountant,view_order_admin'
    }
  },
  // {
  //   name: 'Direct Estimates', // estimation tab for salesRep, Estimator, Estimator HOD
  //   url: '/direct-estimation',
  //   // icon: 'fa fa-lightbulb-o',
  //   data: {
  //     permissions: 'view_projects,create_projects,update_projects,create_project_requirements,' +
  //       'update_project_requirements,view_project_bids,update_estimation,create_estimation,view_estimation',
  //   }
  // },
  {
    name: 'Print Search',
    url: '/print-products/search',
    icon: 'fa fa-clone',
    data: {
      permissions: 'create4over_quote,update4over_quote,view4over_quote'
    },
    //children: [
    //  {
     //   name: 'Search Products',
     //   // icon: 'fa fa-search',
     //   url: '/print-products/search',
     //   data: {
     //     permissions: 'create4over_quote,'
   //     }
   //   },
      // {
      //   name: 'All Quotes',
      //   // icon: 'fa fa-align-justify',
      //   url: '/print-products/quotes',
      //   data: {
      //     permissions: 'view4over_quote,create4over_quote,update4over_quote'
      //   }
      // },
    //  {
    //    name: 'All Orders',
     //   // icon: 'fa fa-opencart',
     //   url: '/print-products/orders',
     //   data: {
     //     permissions: 'create4over_order,view4over_order,update4over_order'
     //   }
    //  }
  //  ]
  },
  {
    name: 'Promo Search', // for supplier also
    url: '/promotional',
    icon: 'fa fa-envelope',
    data: {
      permissions: 'view_sage_products'
    },
    children: [
     {
       name: 'Search Products',
       url: '/promotional/search-products',
       // icon: 'fa fa-search',
       data: {
         permissions: 'view_sage_products'
       }
     },
      {
        name: 'Create Presentation',
        url: '/promotional/quote',
        // icon: 'fa fa-search',
        data: {
          permissions: 'view_quote_cart,view_sage_products'
        }
      },
      {
        name: 'All Presentation',
        url: '/promotional/all-quote',
        // icon: 'fa fa-search',
        data: {
          permissions: 'view_quote_cart'
        }
      }
    ]
  },
 /* {
  name: 'Apparel Search', // for supplier also
  url: '/apparel-new',
  icon: 'fa fa-envelope',
  data: {
    permissions: 'view_sage_products'
  },
  children: [
   {
     name: 'Search Products',
     url: '/apparel-new/apparel-search-products',
     // icon: 'fa fa-search',
     data: {
       permissions: 'view_sage_products'
     }
   },
    {
      name: 'Create Presentation',
      url: '/apparel-new/quote',
      // icon: 'fa fa-search',
      data: {
        permissions: 'view_quote_cart,view_sage_products'
      }
    },
    {
      name: 'All Presentation',
      url: '/apparel-new/quote',
      // icon: 'fa fa-search',
      data: {
        permissions: 'view_quote_cart'
      }
    }
  ]
}, */
  {
    name: 'Apparel Search', // for supplier also
    url: '/apparel',
    icon: 'fa fa-file-alt',
    data: {
      permissions: 'view_sage_products',
    },
    children: [
      {
        name: 'Search Products',
        url: '/apparel/apparel-search-products',
        icon: 'fa fa-search',
        data: {
          permissions: 'view_sage_products'
        }
      },
      {
        name: 'Create Presentation',
        url: '/apparel/quote',
        // icon: 'fa fa-search',
        data: {
          permissions: 'view_quote_cart,view_sage_products'
        }
      },
      {
        name: 'All Presentation',
        url: '/apparel/all-quote',
        // icon: 'fa fa-search',
        data: {
          permissions: 'view_quote_cart,view_sage_products'
        }
      },
      {
        name: 'SP Product Catalog',
        url: '/apparel/product-catalog',
        icon: 'fa fa-search',
        data: {
          permissions: 'view_stakes_mfg_front,view_sage_products'
        }
      },
      {
        name: 'SP Cart',
        url: '/apparel/cart-details',
        icon: 'fa fa-search',
        data: {
          permissions: 'view_stakes_mfg_front,view_sage_products'
        }
      }
    ]
  },
  {
    name: 'Digital Marketing',
    url: '/digital-request',
    icon: 'fa fa-laptop',
    data: {
      permissions: 'view_digital_products,'
    },
    children: [
      {
        name: 'Digital Products',
        url: '/digital-products',
        data: {
          permissions: 'view_digital_products'
        }
      },
      {
        name: 'Digital Proposal',
        url: '/digital-request/digital-proposal',
        data: {
          permissions: 'view_digital_proposal'
        }
      },
      {
        name: 'Site Audit Request',
        url: '/site-audit',
        data: {
          permissions: 'view_digital_products'
        }
      },
    ]
  },
  {
    name: 'Archive Projects', // project tab for salesRep, Estimator, Estimator HOD
    url: '/archive-project',
    icon: 'fa fa-briefcase',
    data: {
      permissions: 'view_projects',
    }
  },
  {
    name: 'Sales Orders',
    url: '/sales-order',
    icon: 'fa fa-chart-line',
    data: {
      permissions: 'view_sales_order',
    }
  },
  // {
  //   name: 'Sales Orders',
  //   url: '/accounting/orders',
  //   icon: 'fa fa-chart-line',
  //   data: {
  //     permissions: 'view_orders_accountant,view_order_admin'
  //   }
  // },
  {
    name: 'Shipping Charge',
    url: '/order-shipping-charge',
    icon: 'fa fa-truck',
    data: {
      permissions: 'create_purchase_order,create_purchase_orders_accountant'
    }
  },
   {
    name: 'Portal Orders',
     url: '/pressero-order',
    icon: 'fa fa-bullhorn',
    data: {
      permissions: 'view_pressero',
    }
  },
  {
    name: 'Purchase Orders', // for supplier only
    url: '/supplier/purchase-order',
    icon: 'fa fa-bullseye',
    data: {
      permissions: 'view_purchase_order_supplier',
      onlySupplier: true
    }
  },
  {
    name: 'Purchase Orders',  // purchase order for est-hod,est,admin
    url: '/purchase-order-detail',
    icon: 'fa fa-bullseye',
    data: {
      permissions: 'list_view_purchase_order',
    }
  },
  // accounting
  // {
  //   name: 'Purchase Orders',
  //   url: '/accounting/purchase-orders',
  //   icon: 'fa fa-bullseye',
  //   data: {
  //     permissions: 'view_purchase_orders_accountant'
  //   }
  // },
  {
    name: 'Order Invoices',
    url: '/order-invoices',
    icon: 'fa fa-wrench',
    data: {
      permissions: 'view_order_invoice',
    }
  },
  {
    name: 'Storefronts Requests',
    url: '/storefronts',
    icon: 'fa fa-table',
    data: {
      permissions: 'view_storefront_requests'
    },
    children: [
      {
        name: 'New Storefront Requests',
        url: '/storefronts/storefront-requests',
        // icon: 'fa fa-strikethrough',
        data: {
          permissions: 'view_storefront_requests'
        }
      },
      {
        name: 'Existing Storefront Update Request',
        url: '/storefronts/storefront-update-request',
        data: {
          permissions: 'view_storefront_update_request'
        }
      },
      // {
      //   name: 'Artwork Requests',
      //   url: '/storefronts/storefront-artwork-request',
      //   // url: '/storefronts/artwork-request',
      //   data: {
      //     permissions: 'view_storefront_artwork_request'
      //   }
      // }
    ]
  },
  {
    name: 'Artwork Requests',
    url: '/storefront-artwork-request',
    icon: 'fa fa-chart-bar',
    // url: '/storefronts/artwork-request',
    data: {
      permissions: 'view_storefront_artwork_request'
    }
  },
  {
    name: 'Campaign Request',
    // url: '/print-products',
    icon: 'fa fa-map',
    data: {
      permissions: 'view_digital_products'
    },
    children: [
      {
        name: 'Content',
        url: '/content-generator',
        data: {
          permissions: 'view_digital_products'
        }
      },
      {
        name: 'Creative',
        url: '/campaign-request/creative-request',  
        // url: '/storefronts/artwork-request',
        data: {
          permissions: 'view_storefront_artwork_request'
        }
      },
      {
        name: 'Campaign',
        url: '/campaign-request',
        data: {
          permissions: 'view_digital_products'
        }
      }
    ]
  },
  {
    name: 'Supplier Directory',
    url: '/supplier/directory-list',
    icon: 'fa fa-plus-square',
    data: {
      permissions: 'view_supplier_directory'
    },
  },
  {
    name: 'Reports',
    url: '/reports',
    icon: 'fa fa-cube',
    data: {
      permissions: 'view_sales_reports,view_commission_report'
    },
    children: [
      {
        name: 'Project Report',
        url: '/reports/project-report',
        data: {
          permissions: 'view_sales_reports'
        }
      },
      {
        name: 'Margin Variant Report',
        url: '/reports/margin-variant-report',
        data: {
          permissions: 'view_sales_reports'
        }
      },
      {
        name: 'Sales Report',
        url: '/reports/sales-report',
        // icon: 'fa fa-strikethrough',
        data: {
          permissions: 'view_sales_reports'
        }
      },
      {
        name: 'Invoice Report',
        url: '/reports/invoice-report',
        // icon: 'fa fa-strikethrough',
        data: {
          permissions: 'view_invoice_report'
        }
      },
      {
        name: 'Commission Report',
        url: '/reports/commission-completd-report',
        // icon: 'fa fa-strikethrough',
        data: {
          permissions: 'view_commission_report'
        }
      },
      {
        name: 'Variant Report',
        url: '/reports/variant-report',
        // icon: 'fa fa-strikethrough',
        data: {
          permissions: 'view_variant_report'
        }
      },
      // {
      //   name: 'Invoice Variant Report',
      //   url: '/reports/invoice-variant-report',
      //   // icon: 'fa fa-strikethrough',
      //   data: {
      //     permissions: 'view_invoice_variant_report'
      //   }
      // }
    ]
  },
  {
    name: 'KPI Report',
    url: '/kpi-report',
    icon: 'fa fa-cube',
    data: {
      permissions: 'view_kpi_report,'
    },
    children: [
      {
        name: 'Presentation Created Report',
        url: '/kpi-report/presentation-created-report',
        data: {
          permissions: 'view_presentation_created_report'
        }
      },
      {
        name: 'Estimate Created Report',
        url: '/kpi-report/estimate-created-report',
        data: {
          permissions: 'view_estimate_created_report'
        }
      },
      {
        name: 'Sales Order Created Report',
        url: '/kpi-report/sales-order-created-report',
        data: {
          permissions: 'view_sales_order_created_report'
        }
      },
      {
        name: 'Commission Due',
        url: '/kpi-report/commission_due_report',
        data: {
        permissions: 'view_commission_due_report'
        }
      },
    ]
  },
  // {
  //   name: 'NEW Hubspot Accounts',
  //   url: '/hubspot-import-contact',
  //   data: {
  //     permissions: 'view_hubspot_import_contact'
  //   }
  // },
  {
    name: 'My Accounts',
    // url: '/print-products',
    icon: 'fa fa-cube',
    data: {
      permissions: 'view_my_accounts'
    },
    children: [
      {
        name: 'Companies',
        url: '/contacts/companies',
        // icon: 'fa fa-address-book',
        data: {
          permissions: 'view_contacts,create_contacts,update_contacts,delete_contacts'
        }
      },
      {
        name: 'Accounts',
        url: '/contacts',
        // icon: 'fa fa-address-book',
        data: {
          permissions: 'view_contacts,create_contacts,update_contacts,delete_contacts'
        },
        linkProps: {
          routerLinkActiveOptions: {
            exact: true
          }
        }
      },
      {
        name: 'NEW Hubspot Accounts',
        url: '/hubspot-import-contact',
        data: {
          permissions: 'view_hubspot_import_contact'
        }
      }
    ]
  },
  {
    name: 'Bills',  // purchase order for est-hod,est,admin
    url: '/bills',
    icon: 'fa fa-cube',
    data: {
      permissions: 'list_view_order_bills'
    }
  },
  // {
  //   name: 'Bills',
  //   url: '/accounting/bills',
  //   icon: 'fa fa-cube',
  //   data: {
  //     permissions: 'view_bills_accountant'
  //   }
  // },
  {
    name: 'Bill payment',
    // url: '/pay-invoice',
    url: '/pay-bill',
    icon: 'fa fa-dollar-sign',
    data: {
      permissions: 'view_pay_bill',
    }
  },
  // {
  //   name: 'Accounts',
  //   url: '/contacts',
  //   // icon: 'fa fa-address-book',
  //   data: {
  //     permissions: 'view_contacts,create_contacts,update_contacts,delete_contacts'
  //   }
  // },
  {         
    name: 'Stakes MFG',       //Stakes MFG
    url: '/stakes-mfg',
    icon: 'fa fa-file-alt',
    data: {
      permissions: 'view_stakes_mfg'
    }
  },
  {
    name: 'Supplier',
    url: '/supplier/list',
    icon: 'fa fa-cube',
    data: {
      permissions: 'view_supplier_list'
    },
  },
  // {
  //   name: 'Receive Payment',
  //   // url: '/pay-invoice',
  //   url: '/pay-invoice',
  //   icon: 'fa fa-cart-plus',
  //   data: {
  //     permissions: 'view_pay_invoice',
  //   }
  // },
  {
    name: 'Setting', // dev settings
    url: '/settings',
    icon: 'fa fa-cog',
    data: {
      onlyAdmin: true
    },
    children: [
      {
        name: 'Permission Category',
        url: '/settings/permission-category'
      },
      {
        name: 'Permission',
        url: '/settings/permission'
      },
      {
        name: 'Role',
        url: '/settings/role'
      },
      {
        name: 'Distributor Network',
        url: '/settings/franchise'
      }
    ]
  },
  {
    name: 'Setting',
    url: '/setting',
    icon: 'fa fa-cog',
    data: {
      permissions: 'create_users,update_users,view_users,update_role_permission,update_franchise,view_digital_service,view_whats_new,view_digital_url,view_digital_question,view_page_intro'
    },
    children: [
      {
        name: 'Users',
        url: '/setting/users',
        // icon: 'fa fa-users',
        data: {
          permissions: 'create_users,update_users'
        }
      },
      {
        name: "What's New",
        url: '/setting/new-update',
        // icon: 'fa fa-users',
        data: {
          permissions: 'view_whats_new'
        }
      },
      {
        name: 'Digital Question',
        url: '/setting/digital-question',
        // icon: 'fa fa-lock',
        data: {
          permissions: 'view_digital_question'
        }

      },
      {
        name: 'Role Permission',
        url: '/setting/role-permission',
        // icon: 'fa fa-lock',
        data: {
          permissions: 'update_role_permission'
        }

      },
      {
        name: 'Digital Url',
        url: '/setting/digital-url',
        // icon: 'fa fa-lock',
        data: {
          permissions: 'view_digital_url'
        }

      },
      {
        name: 'User Hierarchy',
        url: '/setting/user-relation',
        // icon: 'fa fa-users',
        data: {
          permissions: 'update_role_permission'
        }

      },
      {
        name: 'Distributor Network',
        url: '/setting/franchise-detail',
        // icon: 'fa fa-building',
        data: {
          permissions: 'update_franchise'
        }
      },
      {
        name: 'Quickbook Setting',
        url: '/setting/quickbook-setting',
        // icon: 'fa fa-building',
        data: {
          permissions: 'view_quickbook_setting'
        }
      },
      {
        name: 'Tax Rates',
        url: '/setting/tax-rates',
        data: {
          permissions: 'update_franchise'
        }
      },
      {
        name: 'Digital Service',
        url: '/setting/digital-service',
        data: {
          permissions: 'view_digital_service'
        }
      },
      {
        name: 'Digital Service Category',
        url: '/setting/digital-service-category',
        data: {
          permissions: 'view_digital_service_category'
        }
      },
      {
        name: 'Page Introduction', 
        url: '/page-intro',
        data: {
          permissions: 'view_page_intro',
        }
      },
      {
        name: 'Price List', 
        url: '/setting/pricing/list',
        data: {
          permissions: 'view_printing_price,create_printing_price,update_printing_price'
        }
      },
      // {
      //   name: 'Help',
      //   url: '/',
      //   // data: {
      //   //   permissions: 'view_digital_service'
      //   // }
      // }
    ]
  },
  {
    name: 'Orders Details',
    url: '/accounting/order-details',
    icon: 'fas fa-business-time',
    data: {
      permissions: 'view_order_details'
    }
  },
  {
    name: 'Payments',
    url: '/payments',
    icon: 'fas fa-receipt',
    data: {
      permissions: 'view_bill_payments,view_received_payments',
    },
    children: [
      {
        name: 'Received Payments',
        url: '/payments/received',
        data: {
          permissions: 'view_received_payments',
        }
      },
      {
        name: 'Bill Payments',
        url: '/payments/bills',
        data: {
          permissions: 'view_bill_payments',
        }
      },
      {
        name: 'Pay Commission',
        url: '/payments/salesrep',
        data: {
          permissions: 'view_pay_to_sales_rep', //need to change
        }
      }
    ]
  },
  {
    name: 'Accounts',
    url: '/accounts',
    icon: 'fa-credit-card',
    data: {
      permissions: 'view_account_payables,view_account_receivables',
    },
    children: [
      {
        name: 'Accounts Receivables',
        url: '/accounts/receivables',
        data: {
          permissions: 'view_account_receivables',
        }
      },
      {
        name: 'Accounts Payables',
        url: '/accounts/payables',
        data: {
          permissions: 'view_account_payables',
        }
      },
    ]
  },
  {
    name: 'Category',
    url: '/category',
    icon: 'fa fa-list-alt',
    data: {
      permissions: 'view_category,create_category'
    }
  },
  {
    name: 'Campaign Request', // super admin
    // url: '/campaign-request-super',
    icon: 'fa fa-map',
    data: {
      permissions: 'view_category,create_category',
    },
    children: [
      {
        name: 'Campaign Title',
        url: '/campaign-request-title',
        data: {
          permissions: 'view_campaign_request_title,create_campaign_request_title,update_campaign_request_title',
        }
      },
      {
        name: 'Campaign Industry',
        url: '/campaign-request-industry',
        data: {
          permissions: 'view_campaign_request_industry,create_campaign_request_industry,update_campaign_request_industry',
        }
      },
      {
        name: 'Campaign Source',
        url: '/campaign-source',
        data: {
          permissions: 'view_campaign_source',
        }
      },
      {
        name: 'Creative Resource Category',
        url: '/creative-resource-category',
        data: {
          permissions: 'view_creative_resource_category',
        }
      },
      {
        name: 'Creative Resource Document',
        url: '/creative-resource-document',
        data: {
          permissions: 'view_creative_resource_document',
        }
      },
    ]
  },
  // {
  //   name: 'User Hierarchy',
  //   url: '/sales-user-relation',
  //   // icon: 'fa fa-info-circle',
  //   data: {
  //     permissions: 'view_user_hierarchy'
  //   }
  // },
  {
    name: 'SCORECARDS',
    // url: '/scorecard',
    icon: 'fas fa-star',
    data: {
      permissions: 'view_user_hierarchy'
    },
    children: [
      {
        name: 'Brand Guidelines',
        url: '/scorecard/brand-guidelines',
        data: {
          permissions: 'view_user_hierarchy'
        }
      },
      {
        name: 'Sales Scorecard',
        url: '/scorecard',
        data: {
          permissions: 'view_user_hierarchy'
        }
      },
    ]
  },
  {
    name: 'Content Generator',
    url: '/admin-content',
    icon: 'fa fa-info',
    data: {
      permissions: 'view_content_generator_roles,view_content_generator_dropdown,view_content_generator_email'
    },
    children: [
      {
        name: 'Cadence Name',
        url: '/admin-content/role',
        data: {
          permissions: 'view_content_generator_roles'
        }
      },
      {
        name: 'Content Email',
        url: '/admin-content/email-content',
        data: {
          permissions: 'view_content_generator_email'
        }
      },
      {
        name: 'Content Subject',
        url: '/admin-content/dropdown-content',
        data: {
          permissions: 'view_content_generator_dropdown'
        }
      },
    ]
  },
  // accounting end

  // sage start

];