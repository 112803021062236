import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../common.service';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })

export class SageApiService {
  constructor(private _http: HttpClient,
    private _cs: CommonService) {

  }

  getCategories = () => {
    return this._http.get(environment.API_URL + 'sage/get-product-categories');
  }

  /*getPromoCategories = () => {
    return this._http.get(environment.PROMO_STANDARDS_URL + 'aggregate/categories');
  }

  getPromoSubCategories = (value) => {
    return this._http.get(environment.PROMO_STANDARDS_URL + 'aggregate/subCategories?categories=' + value);
  }*/

  getPromoSuppliers = () => {
    return this._http.get(environment.PROMO_STANDARDS_URL + 'aggregate/suppliers');
  }

  getPromoApparelSuppliers = () => {
    return this._http.get(environment.PROMO_STANDARDS_URL + 'aggregate/suppliers?apparel=true');
  }

  getPromoBrands = (suppliers) => {
    return this._http.get(environment.PROMO_STANDARDS_URL + 'aggregate/brands?suppliers=' + encodeURIComponent(suppliers));
  }

  getPromoApparelBrands = (suppliers) => {
    return this._http.get(environment.PROMO_STANDARDS_URL + 'aggregate/brands?apparel=true&suppliers=' + encodeURIComponent(suppliers));
  }

  getPromoCategories = (suppliers=null, brands=null) => {
    if(suppliers && brands){
    return this._http.get(environment.PROMO_STANDARDS_URL + 'aggregate/v2/categories?suppliers=' + encodeURIComponent(suppliers) + '&brands=' + encodeURIComponent(brands));
    }else if(suppliers){
    return this._http.get(environment.PROMO_STANDARDS_URL + 'aggregate/v2/categories?suppliers=' + encodeURIComponent(suppliers));
    }else if(brands){
    return this._http.get(environment.PROMO_STANDARDS_URL + 'aggregate/v2/categories?brands=' + encodeURIComponent(brands));
    }
    return this._http.get(environment.PROMO_STANDARDS_URL + 'aggregate/v2/categories');
}

  getPromoApparelCategories = (suppliers=null, brands=null) => {
    if(suppliers && brands){
      return this._http.get(environment.PROMO_STANDARDS_URL + 'aggregate/v2/categories?apparel=true&suppliers=' + encodeURIComponent(suppliers) +
       '&brands=' + encodeURIComponent(brands));
    }else if(suppliers){
      return this._http.get(environment.PROMO_STANDARDS_URL + 'aggregate/v2/categories?apparel=true&suppliers=' + encodeURIComponent(suppliers));
    }else if(brands){
      return this._http.get(environment.PROMO_STANDARDS_URL + 'aggregate/v2/categories?apparel=true&brands=' + encodeURIComponent(brands));
    }
    return this._http.get(environment.PROMO_STANDARDS_URL + 'aggregate/v2/categories?apparel=true');
  }

  getPromoSubCategories = (suppliers, brands, categories) => {
  return this._http.get(environment.PROMO_STANDARDS_URL + 'aggregate/v2/subCategories?suppliers=' 
    + encodeURIComponent(suppliers) + '&brands=' + encodeURIComponent(brands) + '&categories=' + encodeURIComponent(categories));
  }

  getPromoApparelSubCategories = (suppliers, brands, categories) => {
    return this._http.get(environment.PROMO_STANDARDS_URL + 'aggregate/v2/subCategories?apparel=true&suppliers=' 
    + encodeURIComponent(suppliers) + '&brands=' + encodeURIComponent(brands) + '&categories=' + encodeURIComponent(categories));
  }

  getThemes = () => {
    return this._http.get(environment.API_URL + 'sage/get-product-themes');
  }

  searchProducts = (data) => {
    return this._http.post(environment.API_URL + 'sage/search-products', data);
  }

  searchPromoStandards = (data) => {
    return this._http.post(environment.PROMO_STANDARDS_URL + 'search', data);
  }

  getSelectOptions = (filename) => {
    return this._http.get('../../../assets/json-options/' + filename);
  }

  getProductDetails = (productId) => {
    if (productId.hasOwnProperty("PromoStandardProductId")) {
      //promostandards
      return this._http.get(environment.PROMO_STANDARDS_URL + 'details?productId=' + productId.PromoStandardProductId);
    }else if(productId.hasOwnProperty("ApparelProducId")){
      return this._http.get(environment.PROMO_STANDARDS_URL + 'details?apparel=true&productId=' + productId.ApparelProducId);
    }
    return this._http.post(environment.API_URL + 'sage/product-details', productId);
  }

  getProductLocations = (productId, supplierId) => {
    return this._http.get(environment.PROMO_STANDARDS_URL + 'details/locations/' + supplierId + "/" + productId);

  }

  getSupplierInfo = (supId) => {
    return this._http.get(environment.API_URL + 'sage/supplier-info/' + supId);
  }

  validateQuote = (data) => {
    return this._http.post(environment.API_URL + 'sage/validate-quote', data);
  }

  addToQuote = (data) => {
    return this._http.post(environment.API_URL + 'sage_quote/save-quote-product', data, this._cs.setHeader());
  }

  downloadProductPresentation = (data) => {
    return this._http.post(environment.API_URL + 'sage_quote/download-presentation', data, this._cs.setexportHeader());
  }

  getQuoteCart = () => {
    return this._http.get(environment.API_URL + 'sage_quote/user-quote-cart', this._cs.setHeader());
  }

  removeItem = (id) => {
    return this._http.get(environment.API_URL + 'sage_quote/remove-item/' + id, this._cs.setHeader());
  }

  searchSupplier = (search) => {
    return this._http.post(environment.API_URL + 'sage/search-supplier', { search: search }, this._cs.setHeader());
  }

  addMultipleProducts = (ids) => {
    return this._http.post(environment.API_URL + 'sage_quote/save-multiple-products', ids, this._cs.setHeader());
  }

  getProductQuoteById = (id) => {
    return this._http.get(environment.API_URL + 'sage_quote/product-quote/' + id, this._cs.setHeader());
  }

  generateQuoteOfProducts = (products) => {
    return this._http.post<any>(environment.API_URL + 'sage_quote/generate-product-quote', products, this._cs.setHeader());
  }

  getQuoteById = (id, filter) => {
    return this._http.get<any>(environment.API_URL + 'sage_quote/get-quote/' + id + '/' + filter, this._cs.setHeader());
  }

  // for public link
  getQuote = (id, franchiseId) => {
    return this._http.post(environment.API_URL + 'get-sage-quote/' + id, { id, franchiseId });
  }

  downloadPublicQuote = (data) => {
    return this._http.post(environment.API_URL + 'downolad-pdf', data, { responseType: 'blob' as 'blob' });
  }
  // end

  updateQuoteName = (data) => {
    return this._http.post<any>(environment.API_URL + 'sage_quote/update-quote-name', data, this._cs.setHeader());
  }
  updateQuoteOrder = (data) => {
    return this._http.post(environment.API_URL + 'sage_quote/save-quote-order', data, this._cs.setHeader());
  }

  removeItemFromQuote = (data) => {
    return this._http.post<any>(environment.API_URL + 'sage_quote/remove-quote-item', data, this._cs.setHeader());
  }

  deleteQuote = (id) => {
    return this._http.get(environment.API_URL + 'sage_quote/delete-quote/' + id, this._cs.setHeader());
  }

  downloadQuotePdf = (data) => {
    return this._http.post(environment.API_URL + 'sage_quote/quote-invoice', data, this._cs.setexportHeader());
  }

  savecomment = (data, franchiseId = '') => {
    return this._http.post(environment.API_URL + 'quote-product-comments', data, this._cs.setHeader(franchiseId));
  }
  updateCustomerName = (data) => {
    return this._http.post<any>(environment.API_URL + 'sage_quote/update-quote-customer', data, this._cs.setHeader());
  }
  quoteComments = (data) => {
    return this._http.get<any>(environment.API_URL + 'sage_quote/get-comment/' + data, this._cs.setHeader());
  }
  updatePriceDetails = (data) => {
    return this._http.post<any>(environment.API_URL + 'sage_quote/save-quote-price', data, this._cs.setHeader());

  }
  getProductsData = (data, promostandards) => {
    return this._http.post<any>(environment.API_URL + 'sage_quote/get-multiple-products', { 'products': data, 'promostandards': promostandards }, this._cs.setHeader());

  }
  uploadArt = (data) => {
    let header: any = this._cs.setHeader();
    header['reportProgress'] = true;
    header['observe'] = "events";
    return this._http.post(environment.API_URL + 'sage_quote/save-quote-artwork', data, header);
  }
}

