import { Component, OnInit } from '@angular/core';
import { SageApiService } from '../../../service/sage/sage-api.service';
import { Utils } from '../../../utils';
import { CommonService } from '../../../service/common.service';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  templateUrl: 'public-quote.component.html',
  styleUrls: ['./public-quote.component.scss']
})
export class PublicQuoteComponent implements OnInit {
  products = [];
  status: any = {
    loading: false,
    success: false,
    error: false
  };
  api_error: any = [];
  // for view and edit quote
  quoteData = {};
  franchiseLogo = '';
  loading = false;
  franchiseID = '';
  shipping_details = {};
  // end
  constructor(private sageService: SageApiService,
    private utils: Utils,
    public commonService: CommonService,
    private _activatedRoute: ActivatedRoute,
    private datepipe: DatePipe) { }

  ngOnInit() {
    this._activatedRoute.params.subscribe(
      (params) => {
        if (params['franchise_id'] && params['quote_id']) {
          try { this.franchiseID = atob(params["franchise_id"]); }
          catch (e) { this.franchiseID = atob(decodeURIComponent(params["franchise_id"])); }

          let quoteId = '';
          try { quoteId = atob(params["quote_id"]); }
          catch (e) { quoteId = atob(decodeURIComponent(params["quote_id"])); }

          this.getQuote(quoteId, this.franchiseID);
        }
      }
    );
  }

  getQuote = (quoteId, franchiseId) => {
    this.status = this.utils.showContainers(this.status, 'loading');
    this.sageService.getQuote(quoteId, franchiseId).subscribe(
      (res) => {
        if (res['data']) {
          this.quoteData = res['data'];
          this.shipping_details = JSON.parse(this.quoteData['shipping_details'])
          const logo = this.commonService.UPLOAD_FILE_AWS('franchise_images', false, franchiseId);
          this.franchiseLogo = logo + this.quoteData['franchise_logo'];

          this.products = res['data']['quote_products'];

          this.products.map((pr) => {
            if (!pr['product_details']['Apparel'] && pr['product_details']['SPC'] === 'PromoStandard') {
              this.sageService.getProductLocations(pr['product_details']['Themes'], pr['product_details']['Product_Complaince']).subscribe(
                (res) => {
                  const foundLocation = res['data'].find((val: any) => val.defaultLocation === true)
                  if (foundLocation) {
                    pr['location'] = foundLocation;
                  } else {
                    pr['location'] = res['data'][0];
                  }
                  
                  const decoration = pr['location']['decorationList'].find((val: any) => val.default === true)
                  if (decoration) {
                    pr['decoration'] = decoration;
                  } else {
                    pr['decoration'] = pr['location']['decorationList'][0];
                  }
                },
                (error) => {
                  this.status = this.utils.showContainers(this.status, 'error');
                }
              );
            }
          })

        }
        this.status = this.utils.showContainers(this.status, 'success');
      },
      (error) => {
        this.status = this.utils.showContainers(this.status, 'success');
      }
    );
  }

  //to post comment product wise
  postComment = (product_id, created_by, presentation_id) => {
    this.api_error = {};
    var element = '';
    if (product_id) {
      element = document.getElementById(product_id)["value"];
    } else {
      element = '';
    }
    var postdata = { 'id': product_id, 'comments': element, 'created_by': created_by, 'presentation_id': presentation_id }
    this.sageService.savecomment(postdata, this.franchiseID).subscribe(res => {
      $('#' + product_id + 'error').hide();
      this.ngOnInit();
    }, error => {
      this.api_error = error['error']['data'];
      $('#' + product_id + 'error').show();
    })
  }

  downloadPdf = () => {
    const postData = {};
    postData['products'] = { ...this.products };
    postData['quote_data'] = { ...this.quoteData };
    if (postData['quote_data']['shipping_details']) {
      postData['quote_data']['shipping_details'] = JSON.parse(postData['quote_data']['shipping_details'])
    }
    postData['franchise_logo'] = this.franchiseLogo;
    if (Object.keys(this.quoteData).length > 0) {
      postData['quote_data']['created_at'] = this.datepipe.transform(postData['quote_data']['created_at'].replace(/\s/g, "T"), 'MM-dd-yyyy');
      // this.commonService.getFormatedDate(postData['quote_data']['created_at']);
    }
    // postData['franchise_logo'] = this.franchiseLogo;
    this.loading = true;
    this.sageService.downloadPublicQuote(postData).subscribe(
      (res: any) => {
        this.loading = false;
        const blobData = new Blob([res], { type: 'application/octet-stream' });
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blobData);
        a.download = (this.quoteData['sage_quote_uid'] ? this.quoteData['sage_quote_uid'] : 'SAGE') + '.pdf';
        document.body.appendChild(a);
        a.click();
      },
      (error) => {
        this.loading = false;
      }
    );
  }
}
